import $ from 'jquery';
// import Swiper from 'swiper/bundle';
// import 'swiper/swiper-bundle.css';

// const slider1 = new Swiper('.c-slider01', {
//   loop: true,
//   pagination: {
//     el: '.swiper-pagination',
//   },
//   navigation: {
//     nextEl: '.swiper-button-next',
//     prevEl: '.swiper-button-prev',
//   },
// });
$(function () {
	setTimeout(function () {
		$('.js-mv1-line').addClass('show');
	}, 1000);
});
