import $ from 'jquery';

$('a[href*=\\#]:not([href=\\#])').click(function () {
	if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
		&& location.hostname == this.hostname) {
		let $target = $(this.hash);
		$target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
		let offsetTop = $(".c-header").height();
		// let offsetTop = 0;
		if ($target.length) {
			let targetOffset = $target.offset().top - offsetTop;
			$(this).trigger('anchor-click');
			$('html,body').animate({scrollTop: targetOffset}, 1000);
			return false;
		}
	}
});

var target = window.location.hash, target = target.replace('#', '');
window.location.hash = "";
$(window).on('load', function () {
	if (target) {
		$('html, body').animate({scrollTop: $("#" + target).offset().top - $(".c-header").outerHeight()}, 1000, 'swing', function () {
		});
	}
});
