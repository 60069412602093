import $ from 'jquery';

$(function () {
	let header = $("#header");
	let headerBar = $('#header-navbar');
	let headerNavi = $('#header-navi');
	//let subMenu = $('.is-submenu');

	if (!header.length || !headerNavi.length) {
		return;
	}

	headerBar.on('click', function () {
		$(this).toggleClass('is-active');
		headerNavi.toggleClass('is-active');
		if (headerNavi.hasClass('is-active')) {
			window.setting.bodyFixed(true);
		} else {
			window.setting.bodyFixed(false);
		}
	});

	// subMenu.each(function () {
	// 	const submenu = $(this).next('.c-header__submenu');
	// 	if (submenu.length) {
	// 		submenu.slideDown(0);
	// 		return false;
	// 	}
	// });
	//
	// subMenu.on('click', function (ev) {
	// 	const submenu = $(this).next('.c-header__submenu');
	// 	if (submenu.length) {
	// 		ev.preventDefault();
	// 		submenu.slideToggle(300);
	// 	}
	// });

	header.css("left", $(window).scrollLeft() * -1 + "px");
	$(window).on("scroll", function () {
		header.css("left", $(window).scrollLeft() * -1 + "px");
	});

	$('.c-header__submenu__link').on('anchor-click', function () {
		window.setting.bodyFixed(false);
		headerBar.removeClass('is-active');
		headerNavi.removeClass('is-active');
	});
});
