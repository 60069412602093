
import $ from 'jquery';
$(".js-tab .c-news__category li").click(function () {
  if (!$(this).hasClass("is-active")) {
    $(".js-tab .c-news__category li").removeClass("is-active");
    $(this).addClass("is-active");
    var dataTab = $(this).data("tab");
    $(".c-news__content").removeClass("is-current");
    $("#" + dataTab).addClass("is-current");
  }
});