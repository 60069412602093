
import $ from 'jquery';
var top_main_circle = lottie.loadAnimation({
  container: document.getElementById("top_main_circle"),
  renderer: "svg",
  loop: true,
  autoplay: true,
  path: "/assets/json/01_top_main_circle.json"
});
// ===================================
var top_main_txt = lottie.loadAnimation({
  container: document.getElementById("top_main_txt"),
  renderer: "svg",
  loop: true,
  autoplay: true,
  path: "/assets/json/02_top_main_txt.json"
});
// ===================================
var top_business_details_gray = lottie.loadAnimation({
  container: document.getElementById("top_business_details_gray"),
  renderer: "svg",
  loop: true,
  autoplay: true,
  path: "/assets/json/03_top_business_details_gray.json"
});
var top_business_details_gray1 = lottie.loadAnimation({
	container: document.getElementById("top_business_details_gray1"),
	renderer: "svg",
	loop: true,
	autoplay: true,
	path: "/assets/json/03_top_business_details_gray.json"
});
// ===================================
var top_business_details_white = lottie.loadAnimation({
  container: document.getElementById("top_business_details_white"),
  renderer: "svg",
  loop: true,
  autoplay: true,
  path: "/assets/json/04_top_business_details_white.json"
});
// ===================================
var top_staff_interview = lottie.loadAnimation({
  container: document.getElementById("top_staff_interview"),
  renderer: "svg",
  loop: true,
  autoplay: true,
  path: "/assets/json/05_top_staff_interview.json"
});
// ===================================
var about_us_philosophy = lottie.loadAnimation({
  container: document.getElementById("about_us_philosophy"),
  renderer: "svg",
  loop: true,
  autoplay: true,
  path: "/assets/json/06_about_us_philosophy.json"
});
