import $ from 'jquery';

// Functions
const setting = {
	isObserver: typeof ResizeObserver === "function",
	scrollBarWidth: null,

	// Event Resize
	resizeXTimeout: null,
	resizeXWidth: window.innerWidth,

	// Body Fixed
	bodyFixedTop: null,
	bodyFixedLeft: null,

	sp: function () {
		return window.innerWidth < 768;
	},

	pc: function () {
		return window.innerWidth >= 768;
	},

	getOffsetTop: function (domEl) {
		let offsetTop = domEl.offsetTop;
		if (domEl.offsetParent) {
			offsetTop += window.settings.getOffsetTop(domEl.offsetParent);
		}
		return offsetTop;
	},

	getScrollBarWidth: function () {
		if (setting.scrollBarWidth === null) {
			let div = document.createElement("div");
			div.style.overflow = "scroll";
			div.style.width = "100px";
			div.style.height = "100px";
			div.style.visibility = "hidden";
			document.body.appendChild(div);
			setting.scrollBarWidth = div.offsetWidth - div.clientWidth;
			document.body.removeChild(div);
		}

		return setting.scrollBarWidth;
	},


	eventResizeX: function () {
		//console.log('eventResizeX');
		setting.scrollBarWidth = null;
		if (setting.resizeXWidth !== window.innerWidth) {
			clearTimeout(setting.resizeXTimeout);
			setting.resizeXWidth = window.innerWidth;
			$(window).trigger("resize-x");
			document.body.classList.add("is-resizing");
			setting.resizeXTimeout = setTimeout(function () {
				document.body.classList.remove("is-resizing");
				$(window).trigger("resize-x-end", [setting.getScrollBarWidth()]);
			}, 200);
		}
	},

	bodyFixed: function (fixed = true) {
		let bodyElem = $("body");
		let scrollBarWidth = setting.getScrollBarWidth();
		if (fixed) {
			setting.bodyFixedTop = $(window).scrollTop();
			setting.bodyFixedLeft = $(window).scrollLeft();
			let fixedLeft = 0;
			let overflowX = 'auto';
			if (setting.bodyFixedLeft > 0) {
				fixedLeft = setting.bodyFixedLeft;
				overflowX = 'scroll';
			}
			bodyElem.css({
				position: "fixed",
				top: setting.bodyFixedTop * -1 + "px",
				left: fixedLeft * -1 + "px",
				"overflow-x": overflowX,
				"overflow-y": "hidden",
			}).addClass("is-fixed");
		} else {
			bodyElem.css({
				top: "", left: "", position: "", "overflow-x": "", "overflow-y": "",
			}).removeClass("is-fixed");
			if (setting.bodyFixedTop !== null) {
				$("body, html").scrollTop(setting.bodyFixedTop);
				setting.bodyFixedTop = null;
			}
			if (setting.bodyFixedLeft !== null) {
				$("body, html").scrollLeft(setting.bodyFixedLeft);
				setting.bodyFixedLeft = null;
			}
		}

		bodyElem.trigger("body-fixed", [fixed, scrollBarWidth]);
	},

	isBodyFixed: function () {
		return document.body.classList.contains("is-fixed");
	},

	//
};
window.setting = Object.create(setting);

$(window).on("load", function () {
	$(window).on("orientationchange", window.setting.eventResizeX);

	if (window.setting.isObserver) {
		new ResizeObserver(window.setting.eventResizeX).observe(document.body);
	} else {
		$(window).on("resize", window.setting.eventResizeX);
	}

	window.setting.resizeXWidth = window.innerWidth;
	$(window).trigger("resize-x");
});
